import React, { Component } from 'react';
import "./Form.css";

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            project: '',
            errors: {}
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    };

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        let errors = {}
        let formIsValid = true;

        if (!this.state.name) {
            formIsValid = false;
            errors['name'] = '*Please enter your name.';
        }

        if (!this.state.email) {
            formIsValid = false;
            errors["email"] = "*Please enter your email.";
        }

        if (typeof this.state.email !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(this.state.email)) {
                formIsValid = false;
                errors["email"] = "*Please enter a valid email.";
            }
        }

        if (!this.state.project) {
            formIsValid = false;
            errors["project"] = "*Please enter your project information.";
        }

        if (formIsValid) {
            let nameVal = this.state.name;
            let emailVal = this.state.email;
            let projectVal = this.state.project;
            this.setState({
                name: '',
                email: '',
                project: '',
                errors: {}
            });
            let url = `https://95wu85bxk5.execute-api.us-west-2.amazonaws.com/First/test-post?name=${nameVal}&email=${emailVal}&project=${projectVal}`;
            var request = new Request(url, {
              method: 'POST',
              headers: new Headers({
                'Accept': 'application/json',
                'Content-Type':'application/json',
              })
            });
            fetch(request)
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(()=>{
                alert('Thank you for reaching out! I will be in touch.');
            });
        };
        
    }


    render() {
        return (
            <form method="post" onSubmit={this.handleSubmit} className="contactForm">
                <label>
                    Name
                <span className="req">*</span>
                </label>
                <input
                    name="name"
                    type="text"
                    value={this.state.name}
                    onChange={this.handleChange}
                    className="formInput" />
                <div className="errorMsg">{this.state.errors.name}</div>
                <br />
                <label>
                    Email
                <span className="req">*</span>
                </label>
                <input
                    name="email"
                    type="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    className="formInput" />
                <div className="errorMsg">{this.state.errors.email}</div>
                <br />
                <label>
                    Tell me about your project
                <span className="req">*</span>
                </label>
                <textarea
                    name="project"
                    rows="5"
                    value={this.state.project}
                    onChange={this.handleChange}
                    className="formInput" />
                <div className="errorMsg">{this.state.errors.project}</div>
                <br />
                <button id="submitBtn">Send</button>
            </form >
        );
    }
}
export default Form;
