import React, { Component } from 'react';
import { BrowserRouter as Router, Route} from "react-router-dom";
import './bootstrap.min.css';
import './App.css';
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Projects from "./pages/Projects/Projects";
import ContactForm from "./pages/ContactForm/ContactForm";

class App extends Component {
  render() {
    return (
      <Router>
        <div id="secondRoot">
          <div id="main">
            <Navbar/>
              <Route exact path = "/" component={Home}/>
              <Route exact path = "/about" component={About}/>
              <Route exact path = "/projects" component={Projects}/>
              <Route exact path = "/contact-form" component={ContactForm}/>
          </div>
          <Footer/>
        </div>
      </Router>
    );
  }
}

export default App;
