import React from "react";
import "./ContactForm.css";
import Form from "../../components/Form/Form";

const ContactForm = () => {
    return(
        <div className="container-fluid">
            <div className="row justify-content-md-center">
                <div className="col-md-4 contactSection">
                    Have questions? Ready to work together? You can reach me at contact@katyamacedo.com or use this simple form to leave me a message.
                    I will get back to you in a few days.
                </div>
                <div className="col-md-7 contactSection" id="formSection">
                    <Form/>
                </div>
            </div>
        </div>
    )
}
export default ContactForm;